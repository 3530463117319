import React from "react";
import NavbarItem from "./NavbarItem";

function Navbar(props) {
	return (
		<ul className="main-menu nav">
			<NavbarItem />
		</ul>
	);
}

export default Navbar;
