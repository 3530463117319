import React from "react";
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";

import Blogs from "../../data/Blog/blog";

function Blog() {
	return (
		<div className="blog-area-wrapper sm-top" id="about">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<SectionTitle title="ABOUT US" heading="" />
					</div>
				</div>

				<div className="row mtn-35">
					{Blogs.map((blog) => (
						<BlogItem
							key={blog.id}
							id={blog.id}
							title={blog.title}
							excerpt={blog.content}
							postBy={""}
							date={""}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default Blog;
